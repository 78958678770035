import { baseUrl } from 'app/utils/constant';
import axios from 'axios';

// const axiosInstance = axios.create({
//   baseURL: baseUrl,
// });

const axiosServerClient = axios.create({
  baseURL: baseUrl,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json',
    'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
  },
  withCredentials: false
});


export const axiosAuthClient = axios.create({
  baseURL:baseUrl,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
  },
  withCredentials: true
});
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     let res = error.response;
//     if (res.status == 401) {
//       localStorage.removeItem('accessToken');
//       delete axios.defaults.headers.common.Authorization;

//       // history.push('/session/signin');
//     }
//     console.error('Looks like there was a problem. Status Code: ' + res.status);
//     return Promise.reject((error.response && error.response.data) || 'Something went wrong!')
//   }
// );

// axiosClient.interceptors.response.use(
//   function (response) {
//     return response;
//   }, 
//   function (error) {
//     let res = error.response;
//     if (res.status == 401) {
//       // console.log(error);
//       return window.location.href = '/session/signin'
//     }
//     console.error('Looks like there was a problem. Status Code: ' + res.status);
//     return Promise.reject(error);
//   }
// );

export default axiosServerClient;