import {
    Autocomplete,
    Button,
    Grid,
    Icon,
    styled,
} from "@mui/material";
import { getSubCategories } from "app/api/printNode";
import FileInput from "app/components/FileInput";
import { Span } from "app/components/Typography";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
const TextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
}));


const FormsForm = ({ handleSubmit, tableData, type }) => {

    const [state, setState] = useState({ name: tableData.name, price_color: tableData.price_color, price_black_and_white: tableData.price_black_and_white, sub_category_id: tableData.sub_category_id, sub_category_name: tableData.sub_category_name, sub_category: tableData.sub_category, image: null });
    const [subCategories, setSubCategories] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        if (event.target == undefined) {
            setState({ ...state, image: event });
        } else {

            setState({ ...state, [event.target.name]: event.target.value });
        }
    };

    const handleFileChange = (file) => {
        setState({ ...state, image: file });
    };
    
    const handleThumbnailFileChange = (file) => {
        setState({ ...state, thumbnail_image: file });
    };

    useEffect(() => {
        setLoading(true);

        try {
            getSubCategories(type).then((res) => {
                console.log(res.data.result.data);
                setSubCategories(res.data.result.data);
            })

        } catch (error) {
            setLoading(false);
        }
    }, [])
 
    const { sub_category } = state;
 
    var accept='';
    if(type == 1){
        accept='.pdf,'; 
    } else if(type == 2){
        accept='image/*,';
    } 
    return (
        <div>
            <ValidatorForm onSubmit={() => handleSubmit(state)} onError={() => null}>

                <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                        <TextField
                            type="text"
                            name="name"
                            id="name"
                            value={state.name || ""}
                            onChange={handleChange}
                            errorMessages={["This field is required"]}
                            label="Name"
                            validators={["required"]}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                        <Autocomplete
                            options={subCategories}
                            disableClearable
                            defaultValue={state.sub_category}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                                // setFieldValue("category_id", newValue.id);
                                setState({ ...state, sub_category_id: newValue.id });
                            }}
                            isOptionEqualToValue={(option, value) => { return option.id == value.id }}
                            id="controllable-printer-demo"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Sub Category Name"
                                    value={sub_category && sub_category.name || ""}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                        <FileInput
                            url={state.image?state.image:tableData.file} handleChange={handleFileChange} label={'file'} key={'file'}
                            accept={accept}
                        />
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                        <FileInput
                            url={state.thumbnail_image} handleChange={handleThumbnailFileChange} label={'Thumbnail Image'} key={'thumbnail'}
                        />
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                        <TextField
                            type="number"
                            name="price_color"
                            id="price_color"
                            value={state.price_color || ""}
                            onChange={handleChange}
                            errorMessages={["This field is required"]}
                            label="Price Color"
                            validators={["required"]}
                        />
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                        <TextField
                            type="number"
                            name="price_black_and_white"
                            id="price_black_and_white"
                            value={state.price_black_and_white || ""}
                            onChange={handleChange}
                            errorMessages={["This field is required"]}
                            label="Price black And White"
                            validators={["required"]}
                        />
                    </Grid>

                </Grid>

                <Button color="primary" variant="contained" type="submit" sx={{ mt: 2 }}>
                    <Icon>send</Icon>
                    <Span sx={{ pl: 1, textTransform: "capitalize" }}>Submit</Span>
                </Button>
            </ValidatorForm>
        </div>
    );
};

export default FormsForm;
