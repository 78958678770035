import Cookies from 'js-cookie';
import React, { createContext, useEffect, useState } from 'react';

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {

    
 const setSessionCookie = (session) => {
  Cookies.remove("lang");
  Cookies.set("lang", session, { expires: 14 });
  setLanguage(session);
};
 const getSessionCookie = () => {
  const sessionCookie = Cookies.get("lang");

  if (sessionCookie === undefined) {
    return 'ar';
  } else {
    return sessionCookie;
  }
};

  const [language, setLanguage] = useState(getSessionCookie());

  useEffect(
    () => {
      setLanguage(getSessionCookie());
    },
    [language]
  );


  const toggleLanguage = (lang) => {
    setSessionCookie(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};




export { LanguageContext, LanguageProvider };