import { LoadingButton } from "@mui/lab";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { convertToPdf, printFile } from "app/api/printNode";
import { Formik } from "formik";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import MaxWidthDialog from "../material-kit/dialog/OptimalSizeDialog";
import { getSettings } from "app/api/printNode";
import MultiFileUpload from "app/components/MultiFileUpload";
import enTranslations from "../../utils/en";
import arTranslations from "../../utils/ar";
import { LanguageContext } from "app/contexts/LanguageContext";

// inital login credentials
const initialValues = {
  printerId: "72071599",
  count: 1,
  number: 0,
  color: "gray-scale",
  file: [],
};

const Manual = () => {
  const { printerId } = useParams();
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [state, setState] = useState(initialValues);
  const { language } = useContext(LanguageContext);
  const translations = language == "en" ? enTranslations : arTranslations;

  const reloadPage = () => {
    window.location.reload();
  };
  useState(() => {
    getSettings().then((res) => {
      setPrice(res.data.result.data[0]);
    });
  }, []);

  const handleFormSubmit = (values, { setFieldError }) => {
    setLoading(true);
    if (files.length !== 0) {
      try {
        console.log(files);
        let pageNumber = 0;
        for (let index = 0; index < files.length; index++) {
          let formData = new FormData(); //formdata object
          formData.append("file", files[index]);
          convertToPdf(formData)
            .then((res) => {
              console.log(res.data.result.number);
              pageNumber = pageNumber + res.data.result.number;
              setState({ ...state, number: pageNumber });
              const printData = {
                file_url: res.data.result.pdf_path,
                isColor: state.color != "gray-scale",
                count: parseInt(state.count),
                printer_id: printerId,
              };

              printFile(printData).then((response) => {
                if (index == files.length - 1) {
                  setIsOpen(true);
                  setLoading(false);
                }
              });
            })
            .catch((e) => {
              console.log(e);
              if (e.response.status == 422) {
                Object.entries(e.response.data.result).forEach(([key, value]) =>
                  alert(value)
                );

                setLoading(false);
              }
            });
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      alert("Please Select File");
      reloadPage();
    }
    // setLoading(false);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
    console.log(state);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Formik onSubmit={handleFormSubmit} initialValues={state}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <MultiFileUpload
                    files={files}
                    setFiles={(item) => {
                      setFiles(item);
                    }}
                  />

                  <RadioGroup
                    row
                    fullWidth
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="gray-scale"
                    name="color"
                    onBlur={handleBlur}
                    onChange={(event, newValue) => {
                      setState({ ...state, color: newValue });
                    }}
                    helperText={touched.color && errors.color}
                    error={Boolean(touched.color && errors.color)}
                    sx={{ mb: 1.5 }}
                  >
                    <FormControlLabel
                      value="color"
                      control={<Radio />}
                      label={translations.color}
                    />
                    <FormControlLabel
                      value="gray-scale"
                      control={<Radio />}
                      label={translations.gray_scale}
                    />
                  </RadioGroup>

                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    name="count"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    label={translations.copy}
                    variant="outlined"
                    value={values.count}
                    onChange={(event, newValue) => {
                      console.log(event.target.value);
                      setFieldValue("count", event.target.value);
                      setState({ ...state, count: event.target.value });
                    }}
                    helperText={touched.count && errors.count}
                    error={Boolean(errors.count && touched.count)}
                  />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <LoadingButton
                      type="submit"
                      color="primary"
                      loading={loading}
                      variant="contained"
                      sx={{
                        width: "40%",
                        my: 2,
                        // marginLeft: "25%",
                        backgroundColor: "yellow",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "white", // Change background color on hover
                          color: "black", // Change text color on hover
                        },
                      }}
                    >
                      {translations.print}
                    </LoadingButton>
                    <LoadingButton
                      type="reset"
                      color="primary"
                      loading={loading}
                      variant="contained"
                      sx={{
                        padding:"5px 3px",
                        width: "40%",
                        my: 2,
                        // marginLeft: "25%",
                        backgroundColor: "yellow",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "white", // Change background color on hover
                          color: "black", // Change text color on hover
                        },
                      }}
                      onClick={reloadPage}
                    >
                      {translations.reset}
                    </LoadingButton>
                  </div>
                </div>
              </form>
            )}
          </Formik>
          <MaxWidthDialog
            open={isOpen}
            setIsOpen={setIsOpen}
            state={state}
            setting={price}
            files={files}
            printerId={printerId}
            isForm={false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Manual;
