import { Box, Card, Grid, Paper, Typography, styled } from "@mui/material";
import { H1, H3, H4, Span } from "app/components/Typography";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import enTranslations from "../../utils/en";
import arTranslations from "../../utils/ar";
import { LanguageContext } from "app/contexts/LanguageContext";
import { useContext } from "react";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { getSettings } from "app/api/printNode";

function ManualQrCode(props) {
  const { printerId } = useParams();
  const [link, setLink] = useState('');
  const { language, toggleLanguage } = useContext(LanguageContext);

  const translations = language == "en" ? enTranslations : arTranslations;

  const FlexBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
  }));

  const JustifyBox = styled(FlexBox)(() => ({ justifyContent: "center" }));

  const JWTRoot = styled(JustifyBox)(() => ({
    background: "#E9E6DE",
    minHeight: "100% !important",
    flexDirection: "column",
  }));

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    padding: theme.spacing(3),
    textAlign: "center",
    borderRadius: 15,
    height: 400,
    width: 400,
    [theme.breakpoints.down("md")]: {
      height: 450,
      width: 450,
    },
  }));

  const LgSpan = styled(Span)(({ theme }) => ({
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    justifyContent: 'center',
    padding: '30px',
    marginInline: '12px',
    [theme.breakpoints.up("md")]: { display: 'none' }
  }));

  const SmSpan = styled(Span)(({ theme }) => ({
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    justifyContent: 'center',
    padding: '30px',
    marginInline: '12px',
    [theme.breakpoints.down("md")]: { display: 'none' }
  }));

  useEffect(() => {
    try {
      getSettings().then((res) => {
        var data = res.data.result.data[0];
        console.log(data);
        if (props.type === 'manual-qr' && data) {
          setLink(data.qr_code_fast_print.substring(0,data.qr_code_fast_print.lastIndexOf('/')+1) + printerId);
        } else if (props.type === 'qr' && data) {
          setLink(data.i_print_link.substring(0,data.i_print_link.lastIndexOf('/')+1) + printerId);
        }
      })

    } catch (error) {
      console.log('sdmnbfvsk');
      console.log(error)
    }
  }, [])

  return (
    <JWTRoot>
      <SmSpan>
        <Typography
          variant="h4"
          sx={{
            paddingBottom: "30px",
            color: "black",
            fontWeight: "bolder",
            fontSize: {
              md: "40px",
              xs: "30px"
            }
          }}
        >
          {translations.instruction}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bolder",
            fontSize: "40px",
            color: "black",
          }}
        >
          {translations.instruction_step}
        </Typography>
      </SmSpan>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap="20px"
      >
        <LgSpan>
          <Typography
            variant="h4"
            sx={{
              paddingBottom: "30px",
              color: "black",
              fontWeight: "bolder",
              fontSize: "30px"
            }}
          >
            {translations.instruction}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bolder",
              fontSize: "30px",
              color: "black",
              textAlign: 'center'
            }}
          >
            {translations.instruction_step}
          </Typography>

        </LgSpan>
        <Span marginInline={8}>
          <Item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {link?            <QRCode
              id={printerId}
              title="Qr Code"
              // value={props.type === 'manual-qr' ? data.qr_code_fast_print : data.i_print_link  + printerId}
              value={link}
              // value={props.link + printerId}
              size={250}
              level={"H"}
              includeMagin={true}
              style={{
                height: "auto",
                width: "80%",
              }}
            />: ''}

          </Item>
        </Span>



        <Span marginInline={8}>
          <Item>
            <img
              src="/assets/images/qr-code-animation.gif"
              size={250}
              altl=""
              includeMagin={true}
              style={{
                height: "auto",
                width: "100%",
              }}
            />
          </Item>
        </Span>
      </Grid>



    </JWTRoot >
  );
}

export default ManualQrCode;
