import React, { useContext, useState } from 'react'
import './MultiFile.css'
import enTranslations from '../utils/en';
import arTranslations from '../utils/ar';
import { LanguageContext } from 'app/contexts/LanguageContext';


function MultiFileUpload({files, setFiles} = files) {
   
    const [selectedfile, SetSelectedFile] = useState([]);
    const { language } = useContext(LanguageContext);
    const translations = (language == 'en') ? enTranslations : arTranslations; 

    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const InputChange = (e) => {
        // --For Multiple File Input
        let images = [];
        for (let i = 0; i < e.target.files.length; i++) {
            images.push((e.target.files[i]));
            let reader = new FileReader();
            let file = e.target.files[i];
            reader.onloadend = () => {
                SetSelectedFile((preValue) => {
                    return [
                        ...preValue,
                        {
                            id: e.target.files[i].lastModified,
                            filename: e.target.files[i].name,
                            filetype: e.target.files[i].type,
                            fileimage: reader.result,
                            // datetime: e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                            filesize: filesizes(e.target.files[i].size)
                        }
                    ]
                });
                setFiles((preValue) => {
                    return [
                        ...preValue,
                        e.target.files[i]
                    ]
                });
            }
            if (e.target.files[i]) {
                reader.readAsDataURL(file);
            }
        }
        console.log(selectedfile);
        console.log(files);


    }


    const DeleteFile = async (id) => {
        if (window.confirm("Are you sure you want to delete this Image?")) {
            const result = files.filter((data) => data.id !== id);
            setFiles(result);
        } else {
            // alert('No');
        }
    }

    const DeleteSelectFile = (id) => {
        if (window.confirm("Are you sure you want to delete this Image?")) {
            const selectedfileresult = selectedfile.filter((data) => data.id !== id);
            SetSelectedFile(selectedfileresult);
            const filesresult = files.filter((data) => data.lastModified !== id);
            setFiles(filesresult);
        } else {
            // alert('No');
        }

    }

    const FileUploadSubmit = async (e) => {
        e.preventDefault();

        // form reset on submit 
        e.target.reset();
        if (selectedfile.length > 0) {
            for (let index = 0; index < selectedfile.length; index++) {
                setFiles((preValue) => {
                    return [
                        ...preValue,
                        selectedfile[index]
                    ]
                })
            }
            SetSelectedFile([]);
        } else {
            alert('Please select file')
        }
    }

    return (
        <div className="fileupload-view">
            <div className="row justify-content-center m-0">
                <div className="kb-data-box">
                    <div className="kb-modal-data-title">
                        <div className="kb-data-title">
                            {/* <h6>Multiple File Upload With Preview</h6> */}
                        </div>
                    </div>
                        <div className="kb-file-upload">
                            <div className="file-upload-box">
                                <input type="file" id="fileupload" className="file-upload-input" onChange={InputChange} multiple />
                                <span><span className="file-link">{translations.please_upload_the_document}</span></span>
                            </div>
                        </div>
                        <div className="kb-attach-box mb-3">
                            {
                                selectedfile.map((data, index) => {
                                    const { id, filename, filetype, fileimage, datetime, filesize } = data;
                                    return (
                                        <div className="file-atc-box" key={id}>
                                            {
                                                filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                    <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                    <div className="file-image"><i className="far fa-file-alt"></i></div>
                                            }
                                            <div className="file-detail">
                                                <h6>{filename}</h6>
                                                <p></p>
                                                <p><span>Size : {filesize}</span></p>
                                                <div className="file-actions">
                                                    <button type="button" className="file-action-btn" onClick={() => DeleteSelectFile(id)}>Delete</button>
                                                </div>
                                            </div>  
                                        </div>
                                    )
                                })
                            }
                        </div>
                        
                    {/* {files.length > 0 ?
                        <div className="kb-attach-box">
                            <hr />
                            {
                                files.map((data, index) => {
                                    const { id, filename, filetype, fileimage, datetime, filesize } = data;
                                    return (
                                        <div className="file-atc-box" key={index}>
                                            {
                                                // filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                //     <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                //     <div className="file-image"><i className="far fa-file-alt"></i></div>
                                            }
                                            <div className="file-detail">
                                                <h6>{filename}</h6>
                                                <p><span>Size : {filesize}</span><span className="ml-3">Modified Time : {datetime}</span></p>
                                                <div className="file-actions">
                                                    <button className="file-action-btn" onClick={() => DeleteFile(id)}>Delete</button>
                                                    <a href={fileimage} className="file-action-btn" download={filename}>Download</a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            } 
                        </div>
                        : ''} */}
                </div>
            </div>
        </div>
    )
}

export default MultiFileUpload
