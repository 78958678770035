import { LoadingButton } from '@mui/lab';
import { Card, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { addUserValue, convertToPdf, createUserForm, fillForm, getFormById, getForms, printFile } from 'app/api/printNode';
import { ErrorMessage, Formik } from 'formik';
import { useContext, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MaxWidthDialog from '../material-kit/dialog/OptimalSizeDialog';
import enTranslations from '../../utils/en';
import arTranslations from '../../utils/ar';
import { LanguageContext } from 'app/contexts/LanguageContext';
import { trim } from 'lodash';
import { H2, H4 } from 'app/components/Typography';



const FormField = () => {
    const { formId, printerId } = useParams();
    const navigate = useNavigate();
    const { language } = useContext(LanguageContext);
    const translations = (language == 'en') ? enTranslations : arTranslations;

    const canvasRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [price, setPrice] = useState({});
    const [form, setForm] = useState({});
    const [formField, setFormField] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [state, setState] = useState({
        printerId: printerId,
        count: 1,
        color: 'gray-scale',
    });

    const styles = {
        textField: {
            '& .MuiInput-underline:before': {
                borderBottom: '2px solid black', // White border on the input field
            },
            '& .MuiInput-underline:hover:before': {
                borderBottom: '2px solid black', // White border on hover
            },
        },
    };

    // inital login credentials
    const initialValues = {
        printerId: printerId,
        count: 1,
        color: 'gray-scale',
    };

    useState(() => {
        getFormById(formId).then((res) => {
            setForm(res.data.result.data);
            setFormField(res.data.result.data.form_field);
            setPrice({ price_black_and_white: res.data.result.data.price_black_and_white, price_color: res.data.result.data.price_color });

        })
    }, []);


    const handleFormSubmit = (values, { setFieldError }) => {
        setLoading(true);

        try {

            createUserForm({ form_id: form.id }).then((res) => {
                const user_form_id = res.data.result.id;
                const entries = Object.entries(formField);
                const numEntries = entries.length;
                if (entries.length > 0) {
                    entries.forEach((item, index) => {
                        if (item[1].value != "" && item[1].value != undefined) {

                            const formData = {
                                user_form_details_id: res.data.result.id,
                                form_field_id: item[1].id,
                                value: item[1].value
                            };

                            addUserValue(formData)
                                .then((res) => {
                                    if (index === numEntries - 1) {
                                        setTimeout(() => {
                                            const url = `/generate-image/${user_form_id}/${printerId}`;
                                            navigate(url);
                                        }, 1000)
                                    }
                                });
                        } else {
                            if (index === numEntries - 1) {
                                setTimeout(() => {
                                    const url = `/generate-image/${user_form_id}/${printerId}`;
                                    navigate(url);
                                }, 1000)
                            }
                        }

                    });
                } else {
                    const printData = {
                        file_url: res.data.result.form.image,
                        isColor: state.color != 'gray-scale',
                        count: state.count,
                        printer_id: printerId,
                    };

                    printFile(printData).then((response) => {
                        // setIsOpen(true);
                        // setLoading(false);
                        const url = `/print-success/${printerId}`;
                        navigate(url);
                    })
                }


            })
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const validateForm = (values) => {


        var errors = {};

        const entries = Object.entries(formField);
        // const numEntries = entries.length;
        if (entries.length > 0) {
            entries.forEach((item, index) => {
                if (item[1].is_required == '1') {
                    if (typeof item[1].value == 'undefined' || !item[1].hasOwnProperty('value')) {
                        let label = item[1].label;
                        errors = {
                            ...errors,
                            [item[1].label]: `${label} is required`
                        }

                    } else {
                        if (trim(item[1].value) == '') {
                            let label = item[1].label;
                            errors = {
                                ...errors,
                                [item[1].label]: `${label} is required`
                            }
                            // errors.{item[1].label} = `${label} is required`;

                        }
                    }
                };
            })
        }
        return errors;
    };

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >

            <Box key={0}>

                <center>
                    <Typography
                        variant="h4"
                        sx={{
                            paddingBottom: '60px',
                            color: 'black'
                        }}
                    >
                        {'Fill the necessary details to proceed with the printing request'}
                    </Typography>
                </center>
            </Box>
            <Grid
                container
                item
                sx={{
                    width: '100%',
                    padding: '0 15px',
                    boxSizing: 'border-box',
                    margin: '0'
                }}
                gap={'20px'}
                spacing={5}
                justifyContent="center"
                alignItems="center"

            >
                <Formik
                    validate={validateForm}
                    onSubmit={handleFormSubmit}
                    initialValues={state}
                // validationSchema={validationSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div 
                                style={{
                                    display: 'flex',
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                            {form.form_field && form.form_field.map((key, value) => {
                                if (key.field_type == 'Text Field') {

                                    return (<div>
                                        <TextField
                                            fullWidth
                                            row
                                            required={key.is_required}
                                            key={key.id}
                                            size="small"
                                            name={key.label}
                                            label={key.label}
                                            variant="outlined"
                                            sx={{
                                                my: 2,
                                                marginBottom: '10px',
                                            }}
                                            value={(typeof formField[value] !== 'undefined' && formField[value].hasOwnProperty('value')) ? formField[value].value : ''}
                                            onChange={(event, newValue) => {
                                                setFormField({
                                                    ...formField,
                                                    [value]: {
                                                        ...formField[value],
                                                        value: event.target.value
                                                    }
                                                });
                                            }}
                                        />
                                        <ErrorMessage name={key.label} component="div" />
                                    </div>
                                    )
                                } else if (key.field_type == 'Drop Down') {
                                    return <FormControl >
                                        <InputLabel id="demo-simple-select-label">{key.label}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            key={key.id}
                                            name={key.label}
                                            label={key.label}
                                            variant="outlined"
                                            sx={{
                                                marginBottom: '10px',
                                            }}
                                            value={(typeof formField[value] !== 'undefined' && formField[value].hasOwnProperty('value')) ? formField[value].value : ''}
                                            onChange={(event, newValue) => {
                                                setFormField({
                                                    ...formField,
                                                    [value]: {
                                                        ...formField[value],
                                                        value: event.target.value
                                                    }
                                                });
                                            }}

                                        >
                                            {key.FormFieldDropdown.map(item => {

                                                return <MenuItem value={item.name}>{item.name}</MenuItem>

                                            })}
                                            {/* <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem> */}
                                        </Select>
                                        <ErrorMessage name={key.label} component="div" />
                                    </FormControl>
                                } else if (key.field_type == 'Checkbox') {
                                    return <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    id={key.id}
                                                    key={key.id}
                                                    name={key.label}
                                                    value={(typeof formField[value] !== 'undefined' && formField[value].hasOwnProperty('value')) ? formField[value].value : ''}
                                                    onChange={(event, newValue) => {
                                                        setFormField({
                                                            ...formField,
                                                            [value]: {
                                                                ...formField[value],
                                                                value: event.target.checked
                                                            }
                                                        });
                                                    }}
                                                />
                                            }
                                            label={key.label}
                                        />
                                    </FormGroup>
                                }
                                else {
                                    console.log(key.field_type)
                                }

                            })}

                            {
                                (form.form_field && form.form_field.length == 0) &&
                                <>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="gray-scale"
                                        name="color"
                                        onBlur={handleBlur}
                                        onChange={(event, newValue) => {
                                            setState({ ...state, color: newValue });
                                        }}
                                        helperText={touched.color && errors.color}
                                        error={Boolean(touched.color && errors.color)}
                                        sx={{ mb: 1.5 }}
                                    >
                                        <FormControlLabel value="color" control={<Radio />} label={translations.color} />
                                        <FormControlLabel value="gray-scale"  control={<Radio />} label={translations.gray_scale} />
                                    </RadioGroup>

                                    <H2 sx={{marginBottom: 2}} >{translations.copy_label}</H2>
                                    <TextField
                                        fullWidth
                                        size="large"
                                        type="number"
                                        name="count"
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        label={translations.copy}
                                        variant="outlined"
                                        value={state.count}
                                        color="secondary"
                                        sx={{
                                            marginBottom: '10px',
                                        }}
                                        onChange={(event, newValue) => {
                                            setFieldValue('count', event.target.value);
                                            setState({ ...state, count: event.target.value });
                                        }}
                                        helperText={touched.count && errors.count}
                                        error={Boolean(errors.count && touched.count)}
                                    />
                                </>
                            }

                            <LoadingButton
                                type="submit"
                                color="primary"
                                sx={{
                                    width: '40%',
                                    my: 2,
                                    // marginLeft: "25%",
                                    backgroundColor: "yellow",
                                    color: "black",
                                    "&:hover": {
                                      backgroundColor: "white", // Change background color on hover
                                      color: "black", // Change text color on hover
                                    },
                                  }}
                                loading={loading}
                                variant="contained"
                            >
                                {translations.print}
                            </LoadingButton>
                        </div>
                        </form>
                    )}
                </Formik>
            </Grid>
            { isOpen && (
            <MaxWidthDialog 
                open={isOpen} 
                setIsOpen={setIsOpen} 
                state={state} 
                setting={price} 
                files={{}} 
                printerId={printerId} 
                isForm={true}
            />
            )}
        </Grid >

    );
};

export default FormField;
