const translations = {
    print: 'طباعة',
    reset: 'إعادة تحميل',
    fast_print: 'طباعة',
    select_language: 'اختر لغة الاستخدام',
    copy_label: 'الرجاء اختيار عدد نسخ الطباعة',
    category_title: 'الرجاء الإختيار من القائمة',
    edit_form: 'كتابة علي نموذج',
    ready_form: 'مواد تعليمية',
    payment_center: 'مواد تفاعلية',
    how_do_i_print: 'كيف أطبع؟',
    printing_can_be_done_through_only_three_steps: 'يمكن إجراء الطباعة من خلال ثلاث خطوات فقط',
    choice_the_printing_type: 'اختيار نوع الطباعة (طباعة الصورة أو الطباعة على نموذج مع خيار الكتابة على النموذج قبل الطباعة)',
    click_print: 'انقر فوق "طباعة"',
    take_your_paper_from_the_self_service_machine: 'خذ ورقتك من آلة الخدمة الذاتية',
    printing_will_now_take_place_please_take_the_papers_when_the_printing_process_is_finished: 'ستتم الطباعة الآن. يرجى أخذ الأوراق عند انتهاء عملية الطباعة.',
    summary: 'ملخص',
    color: 'لون :',
    copy: 'ينسخ :',
    the_price_of_one_copy: 'سعر النسخة الواحدة :',
    final_price: 'السعر النهائي:',
    ok: 'نعم',
    number_of_pages: 'عدد الصفحات :',
    category: "فئة",
    please_upload_the_document: "الرجاء تحميل الوثيقة",
    gray_scale: 'أبيض و سود',
    link_expired: '',
    link_expired_detail: 'إنتهي الانتظار. الرجاء مس الكود مره أخري',
    instruction: 'تعليمات',
    instruction_step: 'الرجاء فتح كامرة هاتفك و مسح الرمز الظاهر بالشاشة . ثم قم بأختيار الصور المراد طباعتها',
  };
  
  export default translations;